import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  signedIn: false,
  token: "",
  deviceToken:"",
  expired: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
      state.token = action.payload;
      state.expired = new Date().getTime() + 24 * 60 * 60 * 1000;
    },
    onSignOutSuccess: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.token = "";
      state.expired = "";
    },
    setDeviceToken: (state, action) => {
      state.deviceToken = action.payload;
    },
    currentLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
  },
});

export const {
  setUser,
  onSignInSuccess,
  onSignOutSuccess,
  setDeviceToken,
  currentLocation,
} = authSlice.actions;

export default authSlice.reducer;
