import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocationData: (state, action) => {
      state.setLocationData = action.payload;
    },
    setLocationOptions: (state, action) => {
      state.setLocationOptions = action.payload;
    },
  },
});

export const { setLocationData, setLocationOptions } =
  locationSlice.actions;

export default locationSlice.reducer;
