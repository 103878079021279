const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: true,
  pagePerData: 10,
};

export default appConfig;
