import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  useTimeData: {},
};

export const projectsSlice = createSlice({
  name: "useTime",
  initialState,
  reducers: {
    setUseTimeData: (state, action) => {
      state.useTimeData = action.payload;
    },
  },
});

export const { setUseTimeData } = projectsSlice.actions;

export default projectsSlice.reducer;
