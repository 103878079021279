import { THEME_ENUM } from "constants/theme.constant";

export const themeConfig = {
  themeColor: "sky",
  direction: THEME_ENUM.DIR_LTR,
  mode: THEME_ENUM.MODE_LIGHT,
  locale: "en",
  primaryColorLevel: 900,
  cardBordered: true,
  panelExpand: false,
  controlSize: "md",
  navMode: "light",
  layout: {
    type: THEME_ENUM.LAYOUT_TYPE_CLASSIC,
    sideNavCollapse: false,
  },
};
